import request from './request'
function login(key) {
    return request({
        url: '/login',
        method: 'post',
        data:{"key":key}
      })
}
function changePwd(key,uid,newPwd) {
    return request({
        url: '/changePwd',
        method: 'post',
        data:{"key":key,"uid":uid,"newPwd":newPwd}
      })
}
function changeFbToEmail(key,uid,newPwd) {
    return request({
        url: '/changeFbToEmail',
        method: 'post',
        data:{"key":key,"uid":uid,"newPwd":newPwd}
      })
}
function swapMember(key,AUuid,BUuid) {
    return request({
        url: '/swapMember',
        method: 'post',
        data:{"key":key,"AUuid":AUuid,"BUuid":BUuid}
      })
}

function updateMemberTypeByReceipt(key,uuid) {
    return request({
        url: '/updateMemberTypeByReceipt',
        method: 'post',
        data:{"key":key,"Uuid":uuid}
      })
}
export default {
    login,
    changePwd,
    changeFbToEmail,
    swapMember,
    updateMemberTypeByReceipt
}