import axios from 'axios'

// 创建axios实例
const service = axios.create({
  baseURL: "https://backend2api.greattimemusic.com/member", // api的base_url
  timeout: 100000 // 请求超时时间
})

// request拦截器
// service.interceptors.request.use(config => {
//   return config;
// }, error => {
//   console.log(error) // for debug
//   Promise.reject(error)
// })

// // respone拦截器
// service.interceptors.response.use(res => {
//     console.log(res.data)
//     return res
//   },
//   error => {
//     // console.log('err' +JSON.stringify(error)) // for debug
//     // const config = response.config
//     if (error.response) {
//       console.log(`error.response.status:${error.response.status}`)
//       Vue.router.replace({
//         name:"Login"
//       })
//       throw error.response.data;      
//     }
//   }
// )

export default service
