<template>
  <div class="home">
    <h1>時大音樂 第二後台</h1>
    <h2>密碼登入</h2>
    <div>
      <input v-model="key" />&#20;
      <button @click="login">登入</button>
    </div>
    <p>登入後請勿刷新畫面，若刷新需重新登入</p>
  </div>
</template>

<script>
// @ is an alias to /src
import Api from '@/api';

export default {
  name: 'LoginView',
  components: {
    
  },
  data() {
    return {
      key:""
    }
  },
  methods :{
    login() {
      Api.login(this.key).then(res => {
        let result = res.data
        if(result != null && result.split(";")[0] == "OK") {
          this.$store.state.key =  this.key;
          this.$router.replace({
            name:"system"
          })
        } else {
          alert(res.data)
        }
      })
    }
  }
}
</script>
